
@import "../variables.less";
.mymessage_wrap{
    position: absolute;
    top: 60px;
    right: 10px;
    z-index: 108;
    pointer-events: all;
    color:@colorGary;
    box-shadow: 0px 0px 5px 1px #80b7fb;
    background:#fff;
    border-radius: 4px;
    width: 400px;
    .mymessage_title{
        display:flex;
        font-size: 18px;justify-content: space-evenly;
        padding: 9px 0;
        &>div{
            display:flex;
            text-align:center;
            flex-wrap: wrap;
            cursor:pointer;
            &>span, &>p{
                width: 100%;
                &>span{
                    color:@colorGary;
                    font-size: 12px;
                    margin-left: 3px;
                }
            }
            .mymessage_title_p0{
                color: @colorRed;
            }
            .mymessage_title_p1{
                color: @colorYellow;

            }
            .mymessage_title_p2{
                color: @baseColor;

            }
        }
        .mymessage_title_active{
            font-weight: 600;
        }
    }
    .gary_line{
        width: calc(100% - 20px);
        height: 2px;
        position:relative;
        left: 10px;
        bottom:0;
        background-color: rgb(247 247 250);
        &::after{
            content:"";
            position:absolute;
            width: 8px;
            height: 2px;
            top: 0px;
            background: #e0e2e3;
            right: 0;
        }
        &::before{
            content:"";
            position:absolute;
            width: 8px;
            height: 2px;
            top: 0;
            background: #e0e2e3;
            left: 0;
        }
    }
    #mymessage_Dom{
        height: 50vh;
        overflow: auto;
    }
    &:before{
        content:"";
        position:absolute;
        right: 65px;
        top:-29px;
        border-top: 15px solid transparent;
        border-bottom: 15px solid rgb(244 248 251);
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        z-index: 1;
    }
    ul{
        padding: 0 6px;
        width: 390px;
        li{
            font-size: 14px;
            padding:5px;
            margin: 3px 0;
            display: flex;    align-items: center;
            justify-content: space-between;
            cursor:pointer;
            img{
                width:20px;
                margin: 0 8px 0 0 ;transform: rotate(45deg);
            }
            .message_p1{
                margin: 0 8px 0 0 ;
            }
            .message_p1_3{
                color: #ef7878;
            }
            .message_p1_1{
                color: #eac181;
            }
            .message_p1_2{
                color: #8495e0;
            }
            .message_p3{
                width: 70px;
                color: #999;
                font-size: 12px;
            }
            .message_p2{
                color: @colorGary;
                // flex-grow: 1;
                width: 234px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
        }
        .floor_active{
            background:@baseColor;border-radius: 10px;color:#ffffff;
        }
    }
    .mymessage_time{
        padding: 5px 5px;
        display:flex;justify-content: center;
    }
}
