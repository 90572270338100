.bimface_kongjian {
  position: absolute;
  bottom: 20%;
  left: 20px;
  display: flex;
  z-index: 10;
  text-align: center;
  cursor: pointer;
  flex-direction: column;
  pointer-events: all;
  background: rgb(244 248 251);
  color: #788CA1;
  padding: 10px 6px;
  border-radius: 4px;
}
.bimface_kongjian ul li {
  font-size: 12px;
  padding: 5px;
  margin: 3px 0;
}
.bimface_kongjian ul li span {
  float: left;
  width: 14px;
  position: relative;
}
.bimface_kongjian ul li span img {
  float: left;
  width: 14px;
  position: absolute;
  top: 3px;
  left: 4px;
}
.bimface_kongjian ul .floor_active {
  background: #4261ED;
  border-radius: 10px;
  color: #ffffff;
}
