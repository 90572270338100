.content {
  border-radius: 6px;
  position: absolute;
  color: white;
  user-select: none;
  pointer-events: all;
  display: flex;
  flex-direction: column;
}
.content .title {
  text-align: center;
  font-size: 18px;
  line-height: 50px;
  display: block;
  padding: 0;
}
.content .foot {
  flex-grow: 1;
  overflow: auto;
  flex-shrink: 0;
  max-height: 170px;
}
.dragIcon {
  float: left;
  font-size: 18px;
  line-height: 50px;
  margin-left: 18px;
}
.dragAttachIcon {
  float: right;
  font-size: 18px;
  margin-right: 18px;
  line-height: 50px;
  cursor: pointer;
}
.roomDetails {
  border-radius: 5px;
}
.roomDetails .title {
  font-size: 14px;
  border-radius: 5px 5px 0 0;
  padding: 0 10px;
  height: 30px;
  line-height: 30px;
  text-align: left;
}
.roomDetails .title .dragAttachIcon {
  margin-right: 0;
  font-size: 25px;
  line-height: 30px;
}
.roomDetails .foot {
  height: 30px;
  border-radius: 0 0 5px 5px;
}
.roomDetails li {
  font-size: 13px;
  padding: 0 10px;
}
.roomDetails li span {
  margin-left: 5px;
}
