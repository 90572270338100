
.bimface_yunwei{
    width: 100%;
    height: 100%;
    position:fixed;
    #bimfaceId{
        width: 100%;
        height: calc(100% - 60px);
        margin: 60px 0 0;
        transition: width 1s ease;background: #d5dfeb;
    }
}
