<style>
.del_text_wrap{
    font-size: 16px;
    font-weight: 600;
}
</style>
<style lang="less" scoped>
@import "./variables.less";
#root {
    width: 100%;
    height: 100%;
    overflow: hidden;
    --themeColor: #4261ED;
}
*{
    padding: 0;
    margin: 0;
}
iframe{
    border:none;
    transition: width 2s ease;
}
.main {
  z-index: 98;
}
.login {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
@keyframes load {
    0%{
        transform: rotateZ(0deg);
        // opacity: 1;
    }
    100%{
        transform: rotateZ(180deg);
        // opacity: 0;
    }
}
.my_loading{
    position: absolute;
    top: calc(50% - 55px);
    left: calc(50% - 35px);
    animation: load 2s ease infinite;
}
.operationBar {
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 60px;
  color: #fff;
  z-index: 99;
  background:@headBg;
  .logo {
    width: 340px;
    background: url("./assets/logo3.png") center;
    flex-shrink: 0;
    height: 50px;
    border-radius: 23%;
  }

  .menu {
    width: 100%;
    height: 90px;
    display: flex;
    flex-direction: column;
    pointer-events: none;
  }

  .topLevel {
    font-size: 16px;
    // background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.6));
    white-space: nowrap;
    width: 100%;
    display: flex;
    height: 80px;
    align-items: center;
    pointer-events: auto;
    i{
        font-size: 18px;
    }
    nav {
      cursor: pointer;
      display: inline-block;
      width: 100px;
      text-align: center;
      display: flex;
      align-items: center;
      &.active {
        color: #c77a16;
        font-weight: 600;
      }
      i,img{
        margin-right: 3px;
      }

    }

    .additional {
      padding-right: 12px;
      white-space: nowrap;
      display: inline-block;
      vertical-align: bottom;
      text-overflow: ellipsis;
      text-align: right;
      flex-grow: 1;

      span {
        padding: 0 8px;
      }
      .tianqi{
        position: relative;
        img{
            width: 25px;
            height: 25px;
            position:absolute;
            left: -20px;
            top: -4px;
        }
      }
        .out_icon{
            float:right;
            width: 23px;
            cursor:pointer;
        }
        .my_message_tip{
            cursor:pointer;
        }
    }
  }

    .secondLevel {
        display: flex;
        position:relative;
        flex-direction: row;
        flex-wrap: nowrap;
        width: 50%;
        height: 55%;
        overflow-y: visible;
        border-top: 2px solid rgba(0, 0, 0, 0.2);
        pointer-events: auto;
        .functions {
            height: 100%;
            width: 80px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position:relative;
            cursor: pointer;
            background: linear-gradient(@headBg, rgba(24, 23, 42, 0.5));
            padding: 0 12px;
            overflow: visible;
            &:after {
                content: "";
                width: 100%;
                height: 1px;
                position: absolute;
                top: -2px;
                left: 0;
                border-top: 2px solid #c77a16;
            }
            &.active {
                color: #c77a16;
                background: linear-gradient(@headBg, rgba(24, 23, 42, 0.5));
            }
        }
    }
}

.sensor {
  display: inline-flex;
  width: 48%;
  margin: 12px 1%;
  height: 60px;
  font-size: 12px;
  border-radius: 6px;
  background-color: rgba(12, 34, 46, 0.8);
  justify-content: space-around;
  align-items: center;
  padding: 6px;
  vertical-align: bottom;

  .sensorStatus {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &Dot {
    width: 20px;
    height: 20px;
    border-radius: 10px;
  }

  .status {
    &-1 {
      background-color: #e11523;
      filter: drop-shadow(0 0 6px #e11523);
    }

    &-2 {
      background-color: #de8b5b;
      filter: drop-shadow(0 0 6px #de8b5b);
    }

    &-3 {
      background-color: #dec963;
      filter: drop-shadow(0 0 6px #dec963);
    }

    &-4 {
      background-color: #969899;
      filter: drop-shadow(0 0 6px #969899);
    }

    &-5 {
      background-color: #00fa9a;
      filter: drop-shadow(0 0 6px #00fa9a);
    }
  }

  .inside {
    background: radial-gradient(circle, #e11523, #00fa9a);
    width: 80px;
    height: 3px;
  }

  .inside {
    background: radial-gradient(circle, #00fa9a, #e11523);
    width: 80px;
    height: 3px;
  }

  .linear {
    background: linear-gradient(90deg, #00fa9a, #e11523);
    width: 80px;
    height: 3px;
  }

  .revLinear {
    background: linear-gradient(90deg, #e11523, #00fa9a);
    width: 80px;
    height: 3px;
  }
}

.sharp {
  position: absolute;
  margin-left: -9px;
}

.sensorName {
  overflow: hidden;
  max-height: 100%;
}

.sensorFiles {
  max-height: 340px;
  overflow: auto;

  .sensorFile {
    padding: 4px;
    background-color: rgba(12, 34, 46, 0.8);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: #fff;
    display: block;
    border-bottom: 1px solid hsla(201, 59%, 30%, 0.8);
  }
}

.deviceBaseInfo {
  font-size: 14px;
  line-height: 32px;
  background-color: @lightTitleBackground;
  padding: @smallSpace;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  i {
    width: 30px;
    height: 30px;
    display: inline-block;
    text-align: center;
    margin-right: @middleSpace;
  }

  &:not(:last-child):after {
    content: "";
    display: block;
    left: 40px;
    position: absolute;
    right: 0;
    height: 1px;
    border-bottom: 1px solid #536370;
  }
  & > span:last-child {
    font-size: 12px;
    text-align: right;
  }
}

.deviceConnect {
  display: flex;
  flex-direction: row;
  background-color: @lightTitleBackground;
  align-items: center;
  padding: 0 4px;

  .deviceStream {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: 30px;
    height: fit-content;

    &:first-child {
      flex-grow: 1;

      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 16px;
        bottom: 16px;
        width: 1px;
        border-left: 1px solid #fff;
        right: 0;
      }

      span {
        display: block;
        width: 70%;
        text-align: right;

        &:after {
          content: "";
          display: block;
          position: absolute;
          top: 16px;
          width: 24px;
          height: 1px;
          border-bottom: 1px solid #fff;
          right: -27px;
        }
      }
    }

    &:last-child {
      flex-grow: 1;

      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 16px;
        bottom: 16px;
        width: 1px;
        border-left: 1px solid #fff;
        left: 0;
      }

      span {
        display: block;
        width: 70%;

        &:after {
          content: "";
          display: block;
          position: absolute;
          top: 16px;
          width: 24px;
          height: 1px;
          border-bottom: 1px solid #fff;
          left: -27px;
        }
      }
    }
  }
}

.repairList {
  border: 1px solid rgba(255, 255, 255, 0.6);
  margin: 12px @middleSpace;

  .repairItem {
    padding: @smallSpace;

    .icon {
      width: 40px;
      display: inline-block;
      text-align: center;
      line-height: 40px;
      vertical-align: text-bottom;
      font-size: 22px;
    }

    .detail {
      display: inline-block;

      .number {
        color: dodgerblue;
      }

      .describe {
        font-size: @fontSizeD;
      }

      .date {
        color: #ddd;
      }
    }

    &:not(:last-child):after {
      content: "";
      display: block;
      width: 390px;
      position: absolute;
      border-bottom: 1px solid #888;
      bottom: 0;
      right: 0;
    }

    .status {
      float: right;
      text-align: center;
      border-radius: 50px;
      color: white;
      line-height: 26px;
      width: 80px;
      margin-top: 8px;

      &.status-1 {
        background-color: rgba(247, 53, 53, 0.7);

        &:after {
          content: "新建";
        }
      }

      &.status-2 {
        background-color: rgba(50, 162, 220, 0.7);

        &:after {
          content: "处理中";
        }
      }

      &.status-3 {
        background-color: rgba(69, 185, 104, 0.7);

        &:after {
          content: "已解决";
        }
      }

      &.status-4 {
        background-color: rgba(170, 170, 170, 0.7);

        &:after {
          content: "已完成";
        }
      }
    }
  }
}
.footerList {
  position: absolute;
  bottom: 20px;
  left: 190px;
}
.leftList {
  position: absolute;
  top: 40%;
  left: 20px;
  display: flex;
  z-index:10;text-align:center;
  cursor:pointer;
  flex-direction: column;
    pointer-events: all;
    background:rgb(244 248 251);
    color:@colorGary;
    padding: 10px 6px;border-radius: 4px;
  &>div{
    font-size: 12px;
    padding:5px;
    margin: 3px 0;
  }
  .leftList_active{
    background:@baseColor;border-radius: 10px;color:#ffffff;
  }
}
.restView {
  position: static;
  float: left;
}
.roomMessage {
  display: flex;
}
.list-icon {
  display: flex;
  width: 40px;
  align-items: center;
  img {
    height: 17px;
    display: block;
    zoom: normal;
    margin: 0 auto;
  }
}
.roomcontent {
  display: flex;
  flex-grow: 1;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  padding: 10px;
  justify-content: space-between;
}
.tabsContent {
  height: 300px;
  margin: 0 15px 15px 15px;
  border-radius: 0 0 5px 5px;
  border: 1px solid rgba(50, 162, 220, 0.5);
  border-top: none;
  padding-bottom: 10px;
  overflow: auto;
}
.Btns {
  background: green;
  text-align: center;
  border-radius: 10px;
  width: 60px;
  padding: 2px 10px;
  font-size: 12px;
}
.searchModel {
  padding: 10px;
  box-sizing: border-box;
  width: 170px;
  position: absolute;
  bottom: 70px;
  font-family: "微软雅黑";
  background: rgba(16, 30, 43, 0.8);
  border: 1px solid rgba(16, 30, 43, 1);
  border-radius: 5px;
  .searchBody {
    height: 100px;
    overflow: auto;
    font-size: 14px;
    & > div > div {
      overflow: hidden;
      width: 100%;
      padding: 0 3px;
      text-align: left;
      text-overflow: ellipsis;
      font-family: "微软雅黑" !important;
      white-space: nowrap;
      line-height: 25px;
      font-size: 13px;
    }
  }
}
.myinput {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  line-height: 40px;
  font-size: 14px;
  background: rgba(50, 162, 220, 0.2);
  outline: none;
  padding-left: 25px;
  border: none;
}
.myinput::-webkit-input-placeholder {
  color: white;
}
.List-active {
  background: rgba(50, 162, 220, 0.2);
}
.zhe {
  background: url("./assets/zhe2.png") center;
  position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
}
/deep/.roomTabs {
  .ivu-tabs-bar {
    margin-bottom: 0;
  }
  .ivu-tabs-ink-bar {
    visibility: hidden !important;
  }
  .ivu-tabs-nav {
    width: 100%;
    .ivu-tabs-tab {
      padding: 9px 0;
      margin: 0;
      width: 20%;
      div {
        span {
          margin: 0 auto !important;
        }
        img {
          margin: 0 auto !important;
        }
      }
    }
  }
}
// /deep/ .ivu-btn{
//   color: #000;
// }
// table text color
/deep/ .ivu-table-cell{
    color:#333;
}
/deep/ th .ivu-table-cell{
    color:#999;
}
/deep/ .ivu-table:before{
    background-color: #fff0;
}
/deep/ .ivu-modal{
    img, video{
        width: 100%;
    }
}
/deep/ .vjs-button > .vjs-icon-placeholder{
    position:relative;
    top: -15px;
}
/deep/ .ivu-table-cell{
    padding-left: 10px;
    padding-right: 10px;
}
</style>
<template>
<div id="root">
    <div
        style="position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;" v-if="isLogin">
        <div
            style="
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: gray;
            "
            v-if="noModule"></div>
            <!-- :style="{'width':isappDrawBack?'100%':'calc(100% - 750px)'}" -->
        <iframe
            id="frame"
            src="/static/UnityWeb/Unity/dczx/index.html"
            style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; "
            v-else-if="!noModule && !showBimface"></iframe>
        <!-- <img src="./assets/img/loading.png" class="my_loading" v-show="!showBimface&&!showBtnAll"/> -->
        <bimfaceMain v-show="showBimface" :routerType="routerType"></bimfaceMain>
        <floorDom v-if="showBimface" :routerType="routerType"></floorDom>
        <myMessage id="messageDom" ref="messageDom" v-if="showMessage" @close="()=>{this.showMessage = false;}"></myMessage>
        <div class="operationBar">
            <div class="logo"></div>
            <div class="menu" @mouseover="showSecond = true;" @mouseout="showSecond = false;">
                <div class="topLevel">
                    <template v-for="(i, index) in topLevel">
                        <nav
                            v-show="i.isshow"
                            :class="{ active: menuActiveId == i.id }"
                            :key="index"
                            @click="clickMenu(i, index, 1)"
                            @mouseover="handleMenuClick(i, index)">
                            <i class="iconfont" :class="i.icon"></i>
                            {{ i.name }}
                        </nav>
                    </template>
                    <div class="additional">
                        <span class="tianqi">
                            <img :src="weather.img"/>
                             {{weather.temp}}℃</span>
                        <!-- <span
                            >系统主题色：<ColorPicker
                            @on-change="ChangeBaseColor"
                            editable
                            format="rgb"
                            recommend
                            size="small"
                            style="display: inline-block"
                            v-model="baseColor"
                        /></span> -->
                        <span>{{ time }}</span>
                        <span ref="messageIcon" class="my_message_tip"><i class="iconfont">&#xe602; </i>{{ unread }} 个通知</span>
                        <img class="out_icon" @click="goOut" src="@/assets/icon/out.png"/>
                    </div>
                </div>
                <div class="secondLevel" :style="{'opacity':showSecond ? 1: 0, 'width': secondLevel.length*80+'px'}">
                    <div
                        :style="{'left': menuHoverIndex*100+'px'}"
                        :key="i.name"
                        @click="clickMenu(i, index, 2)"
                        class="functions active"
                        v-for="i in secondLevel">
                        <div :style="{color:secondActive === i.name?'#c77a16':'white'}">{{ i.name }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div :style="{ left: visibility }" class="main">
            <RouterView />
            <!-- unity 视角 -->
            <div v-if="!showBimface && showBtnAll" class="leftList">
                <div v-for="item in unityBtns" :key="'unityBtns'+item.value"
                    @click="changePres(item.value)"
                    :class="unityBtnactive == item.value?'leftList_active':''"
                    :title="item.name">{{item.name}}
                </div>
            </div>
            <div v-if="false" class="footerList">
                <div
                    @click="operation('ResetView')"
                    class="iconfont restView"
                    style="left: 168px"
                    title="重置视角">
                    &#xe65e;
                </div>
                <!-- <div @click="operation('ReShader')"
                        class="restView" style="left:236px;padding-top: 6px" title="刨切"><img alt="" src="./assets/sliced.png">
                    </div> -->
                <div
                    @click="poQie"
                    class="iconfont restView"
                    style="
                    left: 90px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    "
                    title="剖切"
                >
                    <img src="./assets/po.png" />
                </div>
                <div
                    @click="manYou"
                    class="iconfont restView"
                    style="
                    left: 165px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    "
                    title="漫游"
                >
                    <img src="./assets/man.png" />
                </div>
                <div
                    @click="ceLiang"
                    class="iconfont restView"
                    style="
                    left: 230px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    "
                    title="测量"
                >
                    <img src="./assets/ce.png" />
                </div>
                <div
                    @click="xuhua"
                    class="iconfont restView"
                    style="
                    left: 230px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    "
                    title="虚化"
                >
                    <img src="./assets/xuhua.png" />
                </div>
                <div
                    class="iconfont restView"
                    style="
                    left: 230px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    "
                    v-show="$route.fullPath !== '/device/elevator/'"
                >
                    <img src="./assets/search.png" @click="changeshow()" title="搜索" />
                    <div class="searchModel" v-show="showSearch == 1">
                    <div class="searchBody">
                        <div
                        style="
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            height: 100%;
                        "
                        v-show="showloading"
                        >
                        加载中...
                        </div>
                        <div v-show="!showloading">
                        <div
                            v-for="(item, index) in searchroom" :key="'searchroom'+index"
                            :class="{ 'List-active': index == myindex1 }"
                            @click="changeRoom(item, index)"
                        >
                            {{ item.number + "-" + item.name }}
                        </div>
                        </div>
                    </div>
                    <div style="position: relative">
                        <input class="myinput" placeholder="请输入房间" v-model="myroom" />
                        <img
                        src="./assets/search.png"
                        style="width: 14px; position: absolute; left: 6px; top: 30px"
                        />
                    </div>
                    </div>
                    <div class="searchModel" v-show="showSearch == 2">
                    <div class="searchBody">
                        <div
                        style="
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            height: 100%;
                        "
                        v-show="showloading"
                        >
                        加载中...
                        </div>
                        <div v-show="!showloading">
                        <div
                            v-for="(item, index) in searchdevice" :key="'searchdevice'+index"
                            :class="{ 'List-active': index == myindex2 }"
                            @click="toFloordevice(item, index)"
                        >
                            {{ item.device_category_name + "-" + item.code }}
                        </div>
                        </div>
                    </div>
                    <div style="position: relative">
                        <input class="myinput" placeholder="请输入设备" v-model="mydevice" />
                        <img
                        src="./assets/search.png"
                        style="width: 14px; position: absolute; left: 6px; top: 30px"
                        />
                    </div>
                    </div>
                </div>
            </div>
      </div>
      <drag
        :height="400"
        :left="60"
        :top="100"
        :width="350"
        @close="showAttach = false"
        showClose
        title="房间信息"
        v-if="showAttach"
        class="roomDetails"
      >
        <Tabs class="roomTabs">
          <TabPane :label="renderLabel.bind(this, 'info')">
            <div class="tabsContent">
              <div class="roomMessage" v-for="(item,index) in attachInfo" :key="'attachInfo'+index">
                <div class="list-icon">
                  <img :src="item.img" alt="" />
                </div>
                <div class="roomcontent">
                  <div>{{ item.name }}</div>
                  <div>{{ item.value }}</div>
                </div>
              </div>
            </div>
          </TabPane>
          <TabPane :label="renderLabel.bind(this, 'repair')">
            <div
              class="tabsContent"
              style="display: flex; align-items: center; justify-content: center"
              v-show="roomMessage.repairlist.length == 0"
            >
              暂无数据
            </div>
            <div class="tabsContent" v-show="roomMessage.repairlist.length > 0">
              <div class="roomMessage" v-for="(item,index) in roomMessage.repairlist" :key="'repairlist'+index">
                <div class="list-icon">
                  <img src="./assets/room/icon_fangjianming.png" alt="" />
                </div>
                <div class="roomcontent" @click="toRepair(item)">
                  <div>
                    <div>{{ item.describe }}</div>
                    <div>
                      {{ item.createtime ? item.createtime.replace("T", " ") : "" }}
                    </div>
                  </div>
                  <div style="display: flex; align-items: center">
                    <div
                      class="Btns"
                      style="
                        background: rgba(170, 170, 170, 0.7);
                        border: 1px solid rgba(170, 170, 170, 0.8);
                      "
                      v-show="item.status == 4"
                    >
                      已关闭
                    </div>
                    <div
                      class="Btns"
                      style="
                        background: rgba(69, 185, 104, 0.7);
                        border: 1px solid rgba(69, 185, 104, 0.8);
                      "
                      v-show="item.status == 3"
                    >
                      已解决
                    </div>
                    <div
                      class="Btns"
                      style="
                        background: rgba(50, 162, 220, 0.7);
                        border: 1px solid rgba(50, 162, 220, 0.8);
                      "
                      v-show="item.status == 2"
                    >
                      处理中
                    </div>
                    <div
                      class="Btns"
                      style="
                        background: rgba(247, 53, 53, 0.7);
                        border: 1px solid rgba(247, 53, 53, 0.8);
                      "
                      v-show="item.status == 1"
                    >
                      新建
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </TabPane>
          <TabPane :label="renderLabel.bind(this, 'device')">
            <div
              class="tabsContent"
              style="display: flex; align-items: center; justify-content: center"
              v-show="roomMessage.devicelist.length == 0"
            >
              暂无数据
            </div>
            <div class="tabsContent" v-show="roomMessage.devicelist.length > 0">
              <div class="roomMessage" v-for="(item,index) in roomMessage.devicelist" :key="'roomMessage'+index">
                <div class="list-icon">
                  <img src="./assets/room/device.png" alt="" />
                </div>
                <div class="roomcontent">
                  <div>{{ item.device_category_name }}-{{ item.code }}</div>
                  <div style="display: flex; align-items: center">
                    {{ item.device_category_name }}
                  </div>
                </div>
              </div>
            </div>
          </TabPane>
          <TabPane :label="renderLabel.bind(this, 'file')">
            <div
              class="tabsContent"
              style="display: flex; align-items: center; justify-content: center"
              v-show="roomMessage.filelist.length == 0"
            >
              暂无数据
            </div>
            <div class="tabsContent" v-show="roomMessage.filelist.length > 0">
              <div class="roomMessage" v-for="(item,index) in roomMessage.filelist" :key="index+'roomMessage.filelist'">
                <div class="list-icon">
                  <img src="./assets/room/device.png" alt="" />
                </div>
                <div class="roomcontent">
                  <div>
                    <a
                      target="_blank"
                      :href="
                        'http://saasfile.bimsheng.com/onlineview?file=http://dfyy.bimsheng.com' +
                        item.document.fileurl
                      "
                      >{{ item.document.filecode }}</a
                    >
                  </div>
                  <div style="display: flex; align-items: center"></div>
                </div>
              </div>
            </div>
          </TabPane>
          <TabPane :label="renderLabel.bind(this, 'video')">
            <div
              class="tabsContent"
              style="display: flex; align-items: center; justify-content: center"
            >
              <img src="./assets/room/shipin.png" />
            </div>
          </TabPane>
        </Tabs>
      </drag>
      <drag
        :height="200"
        :left="60"
        :top="100"
        :width="350"
        @close="showDevice = false"
        class="roomDetails"
        showClose
        title="设备信息"
        v-if="showDevice"
      >
        <Tabs>
          <TabPane :label="renderLabel.bind(this, 'info')">
            <ul
              style="
                margin: 10px 10px 16px 10px;
                border-radius: 5px;
                border: 1px solid #536370;
                overflow: hidden;
              "
            >
              <li :key="i.value" class="deviceBaseInfo" v-for="i in deviceInfo.base">
                <span
                  style="
                    width: 120px;
                    text-align: left;
                    display: inline-block;
                    margin-right: 6px;
                    float: left;
                  "
                  ><i class="iconfont" v-html="i.icon" />{{ i.name }}</span>
                <span style="display: block; margin-left: 120px">{{ i.value }}</span>
              </li>
            </ul>
          </TabPane>
          <TabPane
            :label="renderLabel.bind(this, 'repair')"
            v-if="deviceInfo.repair.length > 0"
          >
            <div class="repairList">
              <div
                :key="i.id"
                class="repairItem"
                v-for="i in deviceInfo.repair"
                @click="toRepair(i)"
              >
                <div class="icon iconfont">&#xe886;</div>
                <div class="detail">
                  <div class="detail">{{ i.describe }}</div>
                  <div class="date">{{ i.createtime.replace("T", "") }}</div>
                </div>
                <div :class="'status-' + i.status" class="status"></div>
              </div>
            </div>
          </TabPane>
          <TabPane :label="renderLabel.bind(this, 'source')">
            <div class="deviceConnect">
              <div class="deviceStream">
                <template v-if="deviceInfo.upstream.length > 0">
                  <span
                    style="cursor: pointer"
                    :key="index"
                    v-for="(i, index) in deviceInfo.upstream"
                    @click="toDevice(i)"
                    >{{ i.name }}</span
                  >
                </template>
                <span v-else>没有上游设备</span>
              </div>
              <div class="deviceStream">→当前设备→</div>
              <div class="deviceStream">
                <template v-if="deviceInfo.downstream.length > 0">
                  <span
                    style="cursor: pointer"
                    :key="index"
                    v-for="(i, index) in deviceInfo.downstream"
                    @click="toDevice(i)"
                    >{{ i.name }}</span
                  >
                </template>
                <span v-else>没有下游设备</span>
              </div>
            </div>
          </TabPane>
          <TabPane
            :label="renderLabel.bind(this, 'monitor')"
            style="max-height: 340px; overflow: auto"
            v-if="deviceInfo.sensor.length > 0"
          >
            <div :key="i.id" class="sensor" v-for="i in deviceInfo.sensor">
              <div class="sensorName">{{ i.name }}</div>
              <div class="sensorStatus">
                <p
                  :title="i.value"
                  style="
                    width: 80px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    text-align: center;
                  "
                >
                  {{ i.value }}
                </p>
                <div
                  :class="i.status"
                  class="sensorDot"
                  v-if="i.range === 0 || i.status === 4"
                ></div>
                <div v-else>
                  <div :style="{ left: i.offset + '%' }" class="sharp">▲</div>
                  <div :class="i.class"></div>
                </div>
              </div>
            </div>
          </TabPane>
          <TabPane
            :label="renderLabel.bind(this, 'file')"
            v-if="deviceInfo.file.length > 0"
          >
            <div class="sensorFiles">
              <a
                :href="
                  'http://saasfile.bimsheng.com/onlineview?file=http://dfyy.bimsheng.com' +
                  i.url
                "
                :key="i.id"
                target="_blank"
                class="sensorFile"
                v-for="i in deviceInfo.file"
                >{{ i.name }}</a
              >
            </div>
          </TabPane>
          <TabPane label="精细模型" v-if="deviceInfo.moduleSrc">
            <iframe src="//about:blank" />
          </TabPane>
        </Tabs>
      </drag>
    </div>
    <div v-else class="login">
      <RouterView />
    </div>
</div>
</template>

<script lang="ts">
    // @ts-nocheck
	import { Button, ColorPicker, TabPane, Tabs, Spin } from "view-design";
	import { Component, Vue, Watch } from "vue-property-decorator";
	import moment from "moment";
	import request from "@/request";
	import drag from "@/components/drag/drag.vue";
	import axios, { AxiosPromise } from "axios";
	import less from "less";
	import { CreateElement, VNode } from "vue";
	import { Route } from "vue-router";
	import bimfaceMain from "@/components/bimfaceModel.vue";
	import floorDom from "@/components/kongjian.vue";
	import myMessage from "@/components/message.vue";
	import meaus from "@/components/common/meau.ts";
	import Cookie from "js-cookie";

	// const router = useRouter();
	// console.log("路由", floorDom, "dsadsa");
	const imgMap = {
		info: require("./assets/E.png"),
		repair: require("./assets/C.png"),
		source: require("./assets/B.png"),
		monitor: require("./assets/A.png"),
		file: require("./assets/D.png"),
		device: require("./assets/room/device.png"),
		video: require("./assets/room/video.png"),
		roombianhao: require("./assets/room/icon_fangjianhao.png"),
		roomtype: require("./assets/room/icon_fangjianleixing.png"),
		roomName: require("./assets/room/icon_fangjianming.png"),
		jzmj: require("./assets/room/icon_jianzhumianji.png"),
		bm: require("./assets/room/icon_shiyongbumen.png"),
		sybm: require("./assets/room/icon_shiyongbumen.png"),
		symj: require("./assets/room/icon_shiyongmianji.png")
	};
	const CancelToken = axios.CancelToken;
	const noModule = false;

    interface menu {
    name: string;
    icon?: string;
    children?: subMenu[];
    }

    interface subMenu {
    name: string;
    icon: string;
    url: string;
    }

    interface deviceInfoTable {
    name: string;
    value: any;
    range?: any;
    icon?: string;
    }

	@Component({
        components: {
            Button,
            drag,
            Tabs,
            TabPane,
            ColorPicker,
            Spin,
            bimfaceMain,
            floorDom,
            myMessage
        }
    })
		export default class App extends Vue {
            showSecond = false;
            showMessage = false;
            isappDrawBack:boolean = true;
            public time: string = "";
            public unread: number = 0;
            public myindex1: number = -1;
            public myindex2: number = -1;
            public myroom: string = "";
            public mydevice: string = "";
            public closePoStatus: boolean = false;
            public closeManStatus: boolean = false;
            public closeCeStatus: boolean = false;
            public closexhStatus: boolean = false;
            public showSearch: number = -1;
            public showloading: boolean = false;
            public searchroom: any[] = [];
            public searchdevice: any[] = [];
            public isRoominfo: boolean = true;
            public isLogin: boolean = true;
            public menuActiveId: any = 0;
            public menuHoverIndex: number = 0;
            public topLevel: menu[] = [];
            public secondActive: string = "";
            public secondLevel: subMenu[] = [];
            public attachInfo: { name: string; value: any }[] = [];
            public roomMessage: any = {
                repairlist: [],
                video: "",
                filelist: [],
                devicelist: []
            };
            public showAttach: boolean = false;
            // public visibility: 0 | string = "9999px";
            public visibility: string = "0";
            public src: string = "";
            public showDevice: boolean = false;
            public deviceInfo: {
                [base: string]: any[] | string;
                moduleSrc: string;
                repair: any[];
            } = {
                base: [],
                sensor: [],
                file: [],
                moduleSrc: "",
                downstream: [],
                upstream: [],
                repair: []
            };
            inUse: string[] = [];
            public readonly noModule: boolean =
                process.env.NODE_ENV === "production" ? false : noModule;
            baseColor: string = this.$store.state.baseColor;
            private cancelToken?: any;
            private locks: (() => void)[] = [];
            private link: HTMLLinkElement = document.createElement("link");
            private MainLink: HTMLLinkElement = document.createElement("link");
            private workLine: Promise<any>[] = [];
            private firstLoad?: Promise<any>;
            private status = {
                ReShader: false
            };
            showBtnAll = false;
            unityBtns = [
                {
                    name: "主视角",
                    value: "3"
                },
                {
                    name: "俯视",
                    value: "2"
                },
                {
                    name: "操场",
                    value: "0"
                },
                {
                    name: "正大门",
                    value: "1"
                }
            ];
            unityBtnactive = "3";
            weather = {
                temp: 20,
                img: require("@/assets/icon/weather/qingtian.svg")
            }
            goOut () {
                request("/logout/").then(res => {
                    this.isLogin = false;
                    this.$router.push("/login");
                });
            }
            clickMenu (i: menu, index: number, type:any) {
                console.log(i.isnewtab);
                if (!i || (i.url === void 0 || i.url === "")) {
                    return;
                }
                if (type === 2) {
                    this.menuActiveId = this.topLevel[this.menuHoverIndex].id;
                    this.secondActive = i.name;
                }
                if (i.name === "系统配置") {
                    window.open(`${ window.location.origin }/config/#/`);
                } else {
                    if (i.isnewtab) {
                        window.open(`${ window.location.origin }/#${ i.url }`);
                    } else {
                        this.$router.push(i.url);
                    }
                }
            }
            public handleMenuClick (i: menu, index: number) {
                this.secondLevel = [];
                if (i.name === "系统配置") {
                    return;
                }
                this.menuHoverIndex = index;
                if (!i.hasOwnProperty("children")) {
                    this.getChildMeaus(i.id).then(data => {
                        i.children = data;
                        this.secondLevel = i.children;
                    });
                }
                if (i.children && i.children.length > 0) {
                    this.inUse = i.children.map((t) => t.href);
                    this.secondLevel = i.children;
                } else {
                    this.inUse = [];
                }
            }

            // 展示bimface
            showBimface: boolean = false;
            routerType: any = null;
            operation (operation: string) {
                let payload: undefined | boolean;
                if (operation !== "ResetView") {
                // @ts-ignore
                payload = this.status[operation] = !this.status[operation];
                }
                this.firstLoad!.then(() => {
                // @ts-ignore
                document
                    .getElementById("frame")
                    .contentWindow.gameInstance.SendMessage(
                    "Canvas",
                    operation,
                    payload ? payload.toString() : ""
                    );
                });
            }

            public getMessage (): void {
                request("/user/message/?user=&status=1&pagesize=1").then(({ data }) => {
                this.unread = data.count;
                setTimeout(() => {
                    this.getMessage();
                }, 15000);
                });
            }
            changeRoom (item: any, index: number) {
                this.myindex1 = index;
                if (!item.room_guid) {
                this.$Message.error("当前房间没有模型录入");
                return;
                }
                // console.log(item);
                // @ts-ignore
                this.$store.commit("findRoom", {
                floor: item.floorid,
                id: item.room_guid,
                path: [item.floorfacilityid, item.floorid, item.id]
                });
            }
            toFloordevice (item: any, index: number) {
                // console.log(item);
                this.myindex2 = index;
                this.$store.commit("changeViewByCode", { id: item.dbid.floor_id, type: "device" });
                // @ts-ignore
                document
                .getElementById("frame")
                .contentWindow.gameInstance.SendMessage(
                    "Canvas",
                    "CameraMove",
                    `${ item.dbid.floor_id }/${ item.guid }`
                );
            }
            restModule (url: string) {
                this.locks.forEach((t) => t());
                this.locks = [];
                // this.visibility = "9999px";
                // @ts-ignore
                this.firstLoad.then(() => {
                // @ts-ignore
                document
                    .getElementById("frame")
                    .contentWindow.gameInstance.SendMessage(
                    "Canvas",
                    "ChangeScenesMassage",
                    String(url)
                    );
                });
                this.status = {
                ReShader: false
                };
                this.firstLoad = new Promise((resolve) => {
                window.end = () => {
                    this.visibility = 0;
                    // @ts-ignore
                    resolve();
                };
                });
            }

            poQie () {
                this.firstLoad!.then(() => {
                // @ts-ignore

                if (!this.closePoStatus) {
                    // @ts-ignore
                    document
                    .getElementById("frame")
                    .contentWindow.gameInstance.SendMessage("Canvas", "ReShader", "true");
                    this.closePoStatus = true;
                    this.$Message.success("打开剖切!");
                } else {
                    // @ts-ignore
                    document
                    .getElementById("frame")
                    .contentWindow.gameInstance.SendMessage("Canvas", "ReShader", "false");
                    this.closePoStatus = false;
                    this.$Message.success("关闭剖切!");
                }
                });
            }

            manYou () {
                this.firstLoad!.then(() => {
                // @ts-ignore

                if (!this.closeManStatus) {
                    // @ts-ignore
                    document
                    .getElementById("frame")
                    .contentWindow.gameInstance.SendMessage("Canvas", "Roma", "true");
                    this.closeManStatus = true;
                    this.$Message.success("开始漫游!");
                } else {
                    // @ts-ignore
                    document
                    .getElementById("frame")
                    .contentWindow.gameInstance.SendMessage("Canvas", "Roma", "false");
                    this.closeManStatus = false;
                    this.$Message.success("结束漫游!");
                }
                });
            }

            xuhua () {
                this.firstLoad!.then(() => {
                // @ts-ignore
                if (!this.closexhStatus) {
                    // @ts-ignore
                    document
                    .getElementById("frame")
                    .contentWindow.gameInstance.SendMessage("Canvas", "ViewSingle", "true/''");
                    this.closexhStatus = true;
                    this.$Message.success("开启虚化!");
                } else {
                    // @ts-ignore
                    document
                    .getElementById("frame")
                    .contentWindow.gameInstance.SendMessage("Canvas", "ViewSingle", "false/''");
                    this.closexhStatus = false;
                    this.$Message.success("关闭虚化!");
                }
                });
            }

            ceLiang () {
                this.firstLoad!.then(() => {
                // @ts-ignore

                if (!this.closeCeStatus) {
                    // @ts-ignore
                    document
                    .getElementById("frame")
                    .contentWindow.gameInstance.SendMessage("Canvas", "MeasuringTool", "true");
                    this.closeCeStatus = true;
                    this.$Message.success("打开测量!");
                } else {
                    // @ts-ignore
                    document
                    .getElementById("frame")
                    .contentWindow.gameInstance.SendMessage("Canvas", "MeasuringTool", "false");
                    this.closeCeStatus = false;
                    this.$Message.success("关闭测量!");
                }
                });
            }

            ChangeBaseColor (color: string) {
                document.getElementById("root").style.setProperty("--themeColor", color);
                this.$store.commit("changeBaseColor", color);
            }

            renderCss () {
                less.render(lessFiles.global, {
                    javascriptEnabled: true,
                    modifyVars: {
                    "@baseColor": this.$store.state.baseColor,
                    path: "/static/UnityWeb/desktop/"
                    },
                    paths: ["/static/UnityWeb/desktop/"]
                })
                .then((data) => {
                    this.MainLink && this.MainLink.remove();
                    const href: string = URL.createObjectURL(new Blob([data.css]));
                    const link = document.createElement("link");
                    link.href = href;
                    link.rel = "stylesheet";
                    document.head.appendChild(link);
                    this.MainLink = link;
                });
                less.render(lessFiles.Home, {
                    javascriptEnabled: true,
                    modifyVars: {
                    "@baseColor": this.$store.state.baseColor,
                    path: "/static/UnityWeb/desktop/"
                    },
                    paths: ["/static/UnityWeb/desktop/"]
                })
                .then((data) => {
                    this.link && this.link.remove();
                    const href: string = URL.createObjectURL(new Blob([data.css]));
                    const link = document.createElement("link");
                    link.href = href;
                    link.rel = "stylesheet";
                    document.head.appendChild(link);
                    this.link = link;
                });
            }
            changeshow () {
                if (this.showSearch == -1) {
                this.showSearch = this.isRoominfo ? 1 : 2;
                } else {
                this.showSearch = -1;
                }
            }

            toDevice (item: any) {
                // console.log(item);
                if (item.guid) {
                // @ts-ignore
                document
                    .getElementById("frame")
                    .contentWindow.gameInstance.SendMessage("Canvas", "CameraMove", `${ item.guid }`);
                }
            }
            toRepair (item: any) {
                // 房间信息弹窗和设备信息弹窗的维修单跳转
                if (item.isactor && item.status !== 4) {
                this.$router.push({
                    path: `/repair/operation/${ item.id }/`
                });
                } else {
                this.$router.push({
                    path: `/repair/detail/${ item.id }/`
                });
                }
            }

            renderLabel (string: string, h: CreateElement): VNode {
                let name: string = "";
                switch (string) {
                case "info":
                    name = "信息";
                    break;
                case "repair":
                    name = "工单";
                    break;
                case "source":
                    name = "朔源";
                    break;
                case "monitor":
                    name = "监测";
                    break;
                case "file":
                    name = "文档";
                    break;
                case "device":
                    name = "设备";
                    break;
                case "video":
                    name = "视频";
                    break;
                default:
                    name = "";
                }
                return h("div", {}, [
                // @ts-ignore
                h("img", {
                    attrs: { src: imgMap[string] },
                    staticStyle: { display: "block", margin: "0 8px" }
                }),
                h(
                    "span",
                    { staticStyle: { display: "block", width: "36px", textAlign: "center" } },
                    name
                )
                ]);
            }

                // 改变视角
                changePres (val: string) {
                    this.unityBtnactive = val;
                    document.getElementById("frame").contentWindow.gameInstance.SendMessage("Canvas", "shitu", `${ val },${ this.isappDrawBack ? 0 : 40 }`);
                }
                getAuth () {
                    return new Promise((resolve, reject) => {
                        request("/user/self/").then(({ data }) => {
                            // console.log(data.data.permissions, "分析分配班组", data.data.permissions.indexOf("分析分配班组") > -1);
                            resolve(data.data.superuser);
                        });
                    });
                }
                getqweather () {
                    request("/qweather/").then(({ data }) => {
                        let mysrc = require("@/assets/icon/weather/qingtian.svg");
                        if (data.now.text.indexOf("雨") > -1) {
                            mysrc = require("@/assets/icon/weather/xiaoyu.svg");
                        } else if (data.now.text.indexOf("雪") > -1) {
                            mysrc = require("@/assets/icon/xue.png");
                        } else if (data.now.text.indexOf("阴") > -1) {
                            mysrc = require("@/assets/icon/weather/yintian.svg");
                        } else if (data.now.text.indexOf("多云") > -1) {
                            mysrc = require("@/assets/icon/weather/duoyun.svg");
                        }
                        this.weather.img = mysrc;
                        this.weather.temp = data.now.temp;
                    });
                }
                getUnityMsg (res) {
                    if (typeof res.data === "string" && res.data.length < 10) {
                        if (res.data == "end") {
                            setTimeout(() => {
                                this.showBtnAll = true;
                                this.isappDrawBack = false;
                                this.changePres(this.unityBtnactive);
                                this.$store.commit("unityEnd");
                            }, 3000);
                        }
                    }
                }
                getMeaus (type:any = "parent__isnull") {
                    let temp = {
                        "ordering": "sequence"
                    };
                    if (type === "parent__isnull") {
                        temp.parent__isnull = true;
                    }
                    return new Promise((resolve) => {
                        request({
                            url: "/system/rest/menu/",
                            params: temp
                        }).then(({ data }) => {
                            resolve(data);
                        });
                    });
                }
                getChildMeaus (id) {
                    return new Promise((resolve) => {
                        request({
                            url: "/system/rest/menu/",
                            params: {
                                "ordering": "sequence",
                                "parent": id
                            }
                        }).then(({ data }) => {
                            resolve(data);
                        });
                    });
                }
            mounted () {
                // 消息点击事件
                document.addEventListener("click", (e) => {
                    // console.log(this.$refs.messageIcon.contains, e.target, this.$refs.messageDom.$el.contains);
                    // 点击查看信息
                    let isSelf = false;
                    if (this.$refs.messageIcon) {
                        let isSelf = this.$refs.messageIcon.contains(e.target);
                        if (isSelf) {
                            this.showMessage = !this.showMessage;
                        }
                    }
                    if (!isSelf) {
                        if (this.$refs.messageDom) {
                            let isSelf2 = this.$refs.messageDom.$el.contains(e.target);
                            if (!isSelf2) {
                                this.showMessage = false;
                            }
                        }
                    }
                });
                // unity交互
                window.addEventListener("message", this.getUnityMsg);

                // 天气接口
                this.getqweather();
                window.Startend = () => {
                    // @ts-ignore
                    document
                        .getElementById("frame")
                        .contentWindow.gameInstance.SendMessage(
                        "Canvas",
                        "SendUrl",
                        window.location.hash
                        );
                    };
                    // 默认系统颜色
                    // this.renderCss();
                    this.time = moment(new Date()).format("YYYY年MMMDD日 dddd HH:mm:ss");
                    setInterval(() => {
                    this.time = moment(new Date()).format("YYYY年MMMDD日 dddd HH:mm:ss");
                    }, 1000);
                    this.getMessage();
                    if (this.noModule) {
                    this.visibility = 0;
                    setTimeout(() => {
                        window.end({ SendMessage: console.log });
                    });
                }
                this.firstLoad = new Promise((resolve) => {
                    window.end = () => {
                        this.visibility = 0;
                        // console.log(this.$route.fullPath);
                        resolve();
                        if (this.$route.fullPath == "/mediicalData/epidemic/") {
                        // @ts-ignore
                        document
                            .getElementById("frame")
                            .contentWindow.gameInstance.SendMessage("Canvas", "DoorControlPoint", "true");
                        }
                    };
                });
                if (this.$route.fullPath.indexOf("device") !== -1) {
                    this.isRoominfo = false;
                } else {
                    this.isRoominfo = true;
                }

                this.$store.subscribe((mutation) => {
                    const type = mutation.type;
                    //   console.log("new event", mutation);
                    if (type === "changeModule") {
                        // console.info("rest workLine");
                        this.restModule(mutation.payload.url);
                        return;
                    } else if (type === "changeBaseColor") {
                        this.renderCss();
                        return;
                    }
                    // @ts-ignore
                    this.firstLoad.then(() => {
                        // console.log("event start", mutation);
                        // @ts-ignore
                        const SendMessage = document.getElementById("frame").contentWindow.gameInstance
                        .SendMessage;
                        if (mutation.type === "changeBoolean") {
                        SendMessage("Canvas", mutation.payload.name, mutation.payload.value.toString());
                        } else if (this.$store.state.showType === "room") {
                        if (type === "changeViewByCode") {
                            // console.log("dddddddd");

                            if (mutation.payload.stop) return;
                            const id = mutation.payload.id;
                            const showCamera = this.$store.state.showCamera;
                            if (id === "") {
                            SendMessage(
                                "Canvas",
                                "ChangeFloor",
                                117 +
                                "/" +
                                showCamera +
                                "/false/" +
                                (!this.$store.state.showWalls).toString() +
                                "/false"
                            );
                            } else {
                            SendMessage(
                                "Canvas",
                                "ChangeFloor",
                                id +
                                "/" +
                                showCamera +
                                "/false/" +
                                (!this.$store.state.showWalls).toString() +
                                "/false"
                            );
                            }
                        } else if (type === "changeColorType") {
                            SendMessage("Canvas", "ChangeRoomType", mutation.payload);
                        } else if (type === "findFloorAndCamera") {
                            SendMessage(
                            "Canvas",
                            "ChangeFloor",
                            `${ mutation.payload[0] }/true/false/false/false/camera:${ mutation.payload[1] }`
                            );
                        } else if (type === "changeWall") {
                            SendMessage("Canvas", "HideWalls", (!mutation.payload).toString());
                        } else if (type === "updateCameraStatus") {
                            if (this.$store.state.floor !== "" && mutation.payload) {
                            SendMessage(
                                "Canvas",
                                "ChangeFloor",
                                this.$store.state.floorValue + "/true/false/false/false"
                            );
                            } else if (!mutation.payload) {
                            SendMessage("Canvas", "ShowCommonCamera", "false");
                            }
                        } else if (type === "selectDevice") {
                            SendMessage("Canvas", "CameraMove", mutation.payload);
                        } else if (type === "findRoom") {
                            SendMessage(
                            "Canvas",
                            "ChangeFloor",
                            mutation.payload.floor +
                                "/false/false/false/false/block:" +
                                mutation.payload.id
                            );
                        } else if (type === "fChangeFloor") {
                            SendMessage("Canvas", "fChangeFloor", mutation.payload.id);
                        } else if (typeof mutation.payload === "boolean") {
                            // ShowEviromentofThewall OpenSpacepointinf
                            SendMessage("Canvas", type, mutation.payload.toString());
                        }
                        } else {
                        if (type === "selectDevice") {
                            SendMessage("Canvas", "CameraMove", mutation.payload);
                        } else if (type === "changeSystem") {
                            SendMessage("Canvas", "SwitchSpeci", mutation.payload);
                        } else if (type === "changeFlow") {
                            SendMessage("Canvas", "Flow", mutation.payload.toString());
                        } else if (type === "changeViewByCode") {
                            this.$store.commit("changeModule", {
                            type: "device",
                            url: mutation.payload.id - 90,
                            floor: mutation.payload.id
                            });
                        }
                        }
                    });
                });
                window.getMessage = (id: string) => {
                    const event: { name: string; value: string } = JSON.parse(id);
                    if (event.name === "camera") {
                        this.$store.commit("updateLiveStream", event.value);
                    } else if (event.name === "Repair") {
                        this.$router.push(`/repair/detail/${ event.value }/`);
                    }
                };

                window.selected = (id: string) => {
                    if (!id) {
                        return;
                    }
                    if (this.$store.state.showType === "room") {
                        // 房间预览
                        this.cancelToken && this.cancelToken();
                        request({
                        url: "/model/rest/pb/guid2obj/",
                        params: {
                            guid: id
                        },
                        cancelToken: new CancelToken((cancel) => {
                            this.cancelToken = cancel;
                        })
                        }).then(({ data }) => {
                        this.attachInfo = [
                            // @ts-ignore
                            { name: "房间名", value: data.rooms[0].Name, img: imgMap["roomName"] },
                            // @ts-ignore
                            { name: "房间号", value: data.rooms[0].number, img: imgMap["roombianhao"] },
                            // @ts-ignore
                            {
                            name: "使用部门",
                            value: data.rooms[0].department__name,
                            img: imgMap["sybm"]
                            },
                            // @ts-ignore
                            {
                            name: "房间类型",
                            value: data.rooms[0].category__name,
                            img: imgMap["roomtype"]
                            },
                            // @ts-ignore
                            {
                            name: "使用面积",
                            value: data.rooms[0].GrossArea ? data.rooms[0].GrossArea + " m²" : "",
                            img: imgMap["symj"]
                            },
                            // @ts-ignore
                            {
                            name: "建筑面积",
                            value: data.rooms[0].NetArea ? data.rooms[0].NetArea + " m²" : "",
                            img: imgMap["roombianhao"]
                            }
                        ];
                        this.showAttach = true;
                        request({
                            url: `/repair/rest/projectevent/?room=${ data.rooms[0].id }&eventtype=2&pagesize=100`
                        }).then((res) => {
                            this.roomMessage.repairlist = res.data.results;
                        });
                        request({
                            url: `/device/device2/?room=${ data.rooms[0].id }&pagesize=100`
                        }).then((res) => {
                            this.roomMessage.devicelist = res.data.results;
                        });
                        request({
                            url: `/file/rest/doc2relate/?retry=5&retryDelay=500&relatetype=Room&relateid=${ data.rooms[0].id }&format=json`
                        }).then((res) => {
                            this.roomMessage.filelist = res.data;
                        });
                        });
                    } else if (this.$store.state.showType === "device") {
                        this.cancelToken && this.cancelToken();
                        this.showDevice = false;
                        request({
                        url: "/device/device/",
                        params: {
                            model__guid: id
                        },
                        cancelToken: new CancelToken((cancel) => {
                            this.cancelToken = cancel;
                        })
                        }).then(({ data }) => {
                        if (data.count === 1) {
                            const device = data.results[0];
                            const baseInfo: Array<deviceInfoTable> = [
                            {
                                name: "名称",
                                icon: "&#xe886;",
                                value: device.device_category_name + device.serialnumber
                            },
                            {
                                name: "编号",
                                icon: "&#xe731;",
                                value: device.code
                            },
                            {
                                name: "位置",
                                icon: "&#xe622;",
                                value: device.spacestr
                            },
                            {
                                name: "设备状态",
                                icon: "&#xe66f;",
                                value:
                                ((status: string): string => {
                                    if (status === "000") {
                                    return "正常";
                                    } else {
                                    let stringArray: string[] = [];
                                    if (status.charAt(0) === "1") {
                                        stringArray.push("维修中");
                                    }
                                    if (status.charAt(1) === "1") {
                                        stringArray.push("维保中");
                                    }
                                    if (status.charAt(2) === "1") {
                                        stringArray.push("有警报");
                                    }
                                    return stringArray.join();
                                    }
                                })(device.status) || "未知"
                            },
                            {
                                name: "盘点状态",
                                icon: "&#xe62d;",
                                value: ["未知", "已盘点", "待盘点", "其他"][device.pandianstatus]
                            },
                            {
                                name: "设备类型",
                                icon: "&#xe676;",
                                value: device.mepsystemname.join()
                            }
                            ];
                            const deviceId = device.id;
                            const workLine: AxiosPromise[] = [
                            request({
                                url: "/device/sensor/",
                                params: {
                                device: deviceId,
                                ordering: "-sensortype__warrantydescription"
                                }
                            }),
                            request({
                                url: "/file/rest/doc2relate/",
                                params: {
                                relatetype: "device",
                                relateid: deviceId
                                }
                            }),
                            request({
                                url: "/device/deviceconnection/",
                                params: {
                                device1_id: deviceId
                                }
                            }),
                            request({
                                url: "/device/deviceconnection/",
                                params: {
                                device2_id: deviceId
                                }
                            }),
                            request({
                                url: "/repair/rest/projectevent/",
                                params: {
                                ProjecteventElement__relatedid: deviceId
                                // ProjecteventElement__relatetype: "%E8%AE%BE%E5%A4%87"
                                }
                            })
                            ];
                            Promise.all(workLine).then(([sensor, doc, downstream, upstream, repair]) => {
                            const sensorData: Array<deviceInfoTable> = [];
                            sensor.data.results.forEach((t: any) => {
                                let temp = {
                                name: t.name,
                                value: t.lastdata,
                                range: 0,
                                status: "status" + "-" + t.laststation,
                                offset: 0,
                                class: ""
                                };
                                if (t.range11 && !isNaN(Number(t.range11))) {
                                if (t.laststation === 4) {
                                    temp.value = "离线";
                                } else {
                                    let range = [0, 100];
                                    if (t.normalrange) {
                                    let baseRange:
                                        | string[]
                                        | number[] = (t.normalrange as string)
                                        .replace(/ /g, "")
                                        .replace(new RegExp(t.unit, "g"), "")
                                        .split("-");
                                    if (baseRange.length === 1) {
                                        if (/>/.test(baseRange[0])) {
                                        const number: string = baseRange[0].replace(/>/, "");
                                        baseRange = [Number(number), Number(number) * 1.5];
                                        } else if (/</.test(baseRange[0])) {
                                        const number: string = baseRange[0].replace(/</, "");
                                        baseRange = [0, Number(number)];
                                        }
                                    }
                                    const span: number = Math.abs(
                                        Number(baseRange[0]) - Number(baseRange[1])
                                    );
                                    range = [
                                        Number(baseRange[0]) - 0.3 * span,
                                        Number(baseRange[1]) + 0.3 * span
                                    ];
                                    } else {
                                    range[1] =
                                        (t.range11 || 0) +
                                        Math.abs(t.range11 || 0 - t.range12 || 0) * 0.3;
                                    }
                                    if (t.range11 && t.range12) {
                                    temp.class = t.range11 > t.range12 ? "inside" : "outside";
                                    } else if (t.range11) {
                                    temp.class = "linear";
                                    } else {
                                    temp.class = "revLinear";
                                    }
                                    // console.log(range);
                                    temp.offset =
                                    Math.abs((t.lastdata - range[0]) / (range[0] - range[1])) * 100;
                                    if (temp.offset > 100) {
                                    temp.offset = 100;
                                    }
                                    temp.range = Number(t.range11);
                                }
                                }
                                sensorData.push(temp);
                            });
                            const docs = doc.data.map((t: any) => ({
                                name: t.document.name,
                                fileType: t.document.filetype,
                                url: t.document.fileurl
                            }));
                            this.showDevice = true;
                            this.deviceInfo = {
                                base: baseInfo,
                                sensor: sensorData,
                                file: docs,
                                moduleSrc: "",
                                downstream: downstream.data.results.map((t: any) => ({
                                name: t.device2.device_category_name + t.device2.code,
                                guid: t.device2.dbid.modelguid
                                })),
                                // @ts-ignore
                                upstream: upstream.data.results.map((t: any) => ({
                                name: t.device1.device_category_name + t.device1.code,
                                guid: t.device1.dbid.modelguid
                                })),
                                repair: repair.data.results
                            };
                            });
                        } else {
                            this.$Message.info("当前设备尚未录入");
                        }
                        });
                    } else if (this.$route.name === "requestAdd") {
                        request({
                        url: "/space/room/",
                        params: {
                            room_guid: id
                        }
                        }).then(({ data }) => {
                        const temp = data.results[0];
                        this.$store.commit("findRoom", {
                            floor: temp.floorid,
                            id,
                            path: [temp.floorfacilityid, temp.floorid, temp.id]
                        });
                        });
                    } else if (this.$route.name === "elevator") {
                        request({
                        url: "/smt/rest/camera/",
                        params: {
                            model__guid: id
                        }
                        }).then(({ data }) => {
                        const value = data.results[0];
                        if (value) {
                            this.$store.commit("updateLiveStream", [value.id, value.cameraUuid].join());
                        } else {
                            this.$store.commit("updateLiveStream", "");
                        }
                        });
                    }
                };
                // 主题色2.0   2023.07.26
                if (document.getElementById("root")) {
                    document.getElementById("root").style.setProperty("--themeColor", this.$store.state.baseColor);
                }
            }
            created () {
                // 查看用户是否登录, 前端获取不到sessionid，所以改成查看用户信息
                request("/user/self/").then(({ data }) => {
                    console.log(data.data.id, "登陆成功");
                }).catch(() => {
                    console.log("登陆失败:", window.location.href);
                    if (window.location.href.indexOf("login") < 0) {
                        this.$router.push("/login");
                    }
                });
            }
            @Watch("myroom")
            ChangeModel (newvalue: string) {
                if (!newvalue) {
                this.searchroom = [];
                return;
                }
                this.showloading = true;
                this.cancelToken && this.cancelToken();
                request({
                url: "/space/room/",
                params: {
                    pagesize: 100,
                    ordering: "floor_id",
                    search: newvalue
                },
                cancelToken: new CancelToken((cancel) => {
                    this.cancelToken = cancel;
                })
                })
                .then((res) => {
                    this.searchroom = res.data.results;
                    this.showloading = false;
                })
                .catch((error) => {
                    console.log(error);
                });
            }

            @Watch("mydevice")
            ChangeDeviceData (newvalue: string) {
                if (!newvalue) {
                this.searchdevice = [];
                return;
                }
                this.showloading = true;
                this.cancelToken && this.cancelToken();
                request({
                url: "/device/device2/",
                params: {
                    pagesize: 100,
                    search: newvalue
                },
                cancelToken: new CancelToken((cancel) => {
                    this.cancelToken = cancel;
                })
                })
                .then((res) => {
                    this.searchdevice = res.data.results;
                    this.showloading = false;
                })
                .catch((error) => {
                    console.log(error);
                });
            }

            @Watch("$route", { immediate: true })
            onRouteChange (
                newValue: { name: string } = { name: this.$route.name || "" },
                old: { name: string } = { name: "" }
            ) {
                // 切换unity,bimface模型
                let routeNames:any = ["home2"];
                this.showBimface = !(routeNames.indexOf(newValue.name) > -1);
                this.routerType = newValue.name;
                // unity init
                this.showBtnAll = false;
                this.isappDrawBack = true;
                if (this.$route.fullPath.indexOf("login") !== -1) {
                    // 是登录页面
                    this.isLogin = false;
                    return;
                } else {
                    this.isLogin = true;
                    // 菜单
                    this.getMeaus().then(data => {
                        this.topLevel = data;
                        // 登陆之后  超级用户管理员才可进入系统配置菜单
                        this.getAuth().then((res:boolean) => {
                            let tempShow:boolean = res;
                            this.topLevel.forEach((item, index) => {
                                if (item.name === "系统配置") {
                                    this.topLevel[index].isshow = tempShow;
                                }
                            });
                        });
                    });
                    // 菜单选中
                    this.getMeaus("all").then(data => {
                        data.forEach(val => {
                            if (val.url === this.$route.fullPath) {
                                this.secondActive = val.name;
                                let arr = val.parent.split("/system/rest/menu/");
                                let arr1 = arr.length >= 2 ? arr[1].split("/") : [""];
                                this.menuActiveId = arr1[0];
                                console.log(this.menuActiveId, val.parent);
                            }
                        });
                    });
                }
                if (this.$route.fullPath.indexOf("device") !== -1) {
                    this.isRoominfo = false;
                } else {
                    this.isRoominfo = true;
                }
                this.mydevice = "";
                this.myroom = "";
                this.showSearch = -1;
                this.myindex1 = -1;
                this.myindex2 = -1;
                this.showAttach = false;
                this.closexhStatus = false;
                this.showDevice = false;
                const oldName = old.name;
                const newName = newValue.name;
                // console.log(oldName, newName);
                if (newName === "deviceMain") {
                this.$store.commit("changeModule", {
                    type: "device",
                    url: "25",
                    floor: 115
                });
                } else if (newName === "elevator") {
                this.$store.commit("changeModule", {
                    type: "elevator",
                    url: "elevator",
                    floor: ""
                });
                } else if (this.$store.state.showType !== "room") {
                    this.$store.commit("changeModule", {
                        type: "room",
                        url: "space",
                        floor: ""
                    });
                }
                if (this.$route.fullPath == "/medicalData/epidemic/") {
                    // @ts-ignore
                    document.getElementById("frame").contentWindow.gameInstance.SendMessage("Canvas", "DoorControlPoint", "true");
                }
                if (newName === "home") {
                    this.$store.commit("changeBoolean", { name: "OpenSpacepointinf", value: true });
                    this.$store.commit("changeBoolean", {
                        name: "ShowEviromentofThewall",
                        value: true
                    });
                } else {
                    this.$store.commit("changeBoolean", {
                        name: "ShowEviromentofThewall",
                        value: false
                    });
                    this.$store.commit("changeBoolean", { name: "OpenSpacepointinf", value: false });
                }
                if (newName === "repairTable") {
                    this.$store.commit("changeBoolean", { name: "OpenRepair", value: "true" });
                    this.$store.commit("changeBoolean", { name: "OpenStation", value: "维保/false" });
                    this.$store.commit("changeBoolean", { name: "OpenStation", value: "维修/true" });
                } else if (newName === "maintenanceTable") {
                    this.$store.commit("changeBoolean", { name: "OpenRepair", value: "true" });
                    this.$store.commit("changeBoolean", { name: "OpenStation", value: "维修/false" });
                    this.$store.commit("changeBoolean", { name: "OpenStation", value: "维保/true" });
                } else if (oldName === "maintenanceTable" || oldName === "repairTable") {
                    this.$store.commit("changeBoolean", { name: "OpenRepair", value: "false" });
                }
            }

            @Watch("$store.state.isDrawBack")
            onisDrawBackchaneg (val) {
                this.isappDrawBack = val;
                this.changePres(this.unityBtnactive);
                // 调整视角居中，靠右
                // console.log("收拉数据");
            }
            private subscribe: () => void = () => void 0;
	}
</script>
