
@import "../variables.less";
.bimface_kongjian{
    position: absolute;
    bottom: 20%;
    left: 20px;
    display: flex;
    z-index:10;text-align:center;
    cursor:pointer;
    flex-direction: column;
    pointer-events: all;
    background:rgb(244 248 251);
    color:@colorGary;
    padding: 10px 6px;border-radius: 4px;
    ul{
        li{
            font-size: 12px;
            padding:5px;
            margin: 3px 0;
            // display:flex;
            // align-items: center;
            // justify-content: center;
            span{
                float:left;
                width: 14px;
                    position:relative;
                img{
                    float:left;
                    width: 14px;
                    position:absolute;
                    top: 3px;
                    left:4px;
                }
            }
        }
        .floor_active{
            background:@baseColor;border-radius: 10px;color:#ffffff;
        }
    }
}
