@font-face {
  font-family: "iconfont"; /* Project id 4242943 */
  src: url('iconfont.woff2?t=1694659650934') format('woff2'),
       url('iconfont.woff?t=1694659650934') format('woff'),
       url('iconfont.ttf?t=1694659650934') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.project-icon-weixiu:before {
  content: "\e63a";
}

.project-icon-zonglan:before {
  content: "\e745";
}

.project-icon-kongjian:before {
  content: "\e7dc";
}

.project-icon-anfang:before {
  content: "\e6e3";
}

.project-icon-nenghao:before {
  content: "\e6e5";
}

.project-icon-qiti:before {
  content: "\e61c";
}

.project-icon-xitong:before {
  content: "\e6a9";
}

.project-icon-shebei:before {
  content: "\e65d";
}

.project-icon-xiangxia:before {
  content: "\eb0a";
}

.project-icon-xiangshang:before {
  content: "\eb0b";
}

.project-icon-yingyong:before {
  content: "\e912";
}

.project-icon-moren:before {
  content: "\e7d4";
}

