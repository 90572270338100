#root {
  width: 100%;
  height: 100%;
  overflow: hidden;
  --themeColor: #4261ED;
}
* {
  padding: 0;
  margin: 0;
}
iframe {
  border: none;
  transition: width 2s ease;
}
.main {
  z-index: 98;
}
.login {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
@keyframes load {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(180deg);
  }
}
.my_loading {
  position: absolute;
  top: calc(50% - 55px);
  left: calc(50% - 35px);
  animation: load 2s ease infinite;
}
.operationBar {
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 60px;
  color: #fff;
  z-index: 99;
  background: #18172a;
}
.operationBar .logo {
  width: 340px;
  background: url("./assets/logo3.png") center;
  flex-shrink: 0;
  height: 50px;
  border-radius: 23%;
}
.operationBar .menu {
  width: 100%;
  height: 90px;
  display: flex;
  flex-direction: column;
  pointer-events: none;
}
.operationBar .topLevel {
  font-size: 16px;
  white-space: nowrap;
  width: 100%;
  display: flex;
  height: 80px;
  align-items: center;
  pointer-events: auto;
}
.operationBar .topLevel i {
  font-size: 18px;
}
.operationBar .topLevel nav {
  cursor: pointer;
  display: inline-block;
  width: 100px;
  text-align: center;
  display: flex;
  align-items: center;
}
.operationBar .topLevel nav.active {
  color: #c77a16;
  font-weight: 600;
}
.operationBar .topLevel nav i,
.operationBar .topLevel nav img {
  margin-right: 3px;
}
.operationBar .topLevel .additional {
  padding-right: 12px;
  white-space: nowrap;
  display: inline-block;
  vertical-align: bottom;
  text-overflow: ellipsis;
  text-align: right;
  flex-grow: 1;
}
.operationBar .topLevel .additional span {
  padding: 0 8px;
}
.operationBar .topLevel .additional .tianqi {
  position: relative;
}
.operationBar .topLevel .additional .tianqi img {
  width: 25px;
  height: 25px;
  position: absolute;
  left: -20px;
  top: -4px;
}
.operationBar .topLevel .additional .out_icon {
  float: right;
  width: 23px;
  cursor: pointer;
}
.operationBar .topLevel .additional .my_message_tip {
  cursor: pointer;
}
.operationBar .secondLevel {
  display: flex;
  position: relative;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 50%;
  height: 55%;
  overflow-y: visible;
  border-top: 2px solid rgba(0, 0, 0, 0.2);
  pointer-events: auto;
}
.operationBar .secondLevel .functions {
  height: 100%;
  width: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  background: linear-gradient(#18172a, rgba(24, 23, 42, 0.5));
  padding: 0 12px;
  overflow: visible;
}
.operationBar .secondLevel .functions:after {
  content: "";
  width: 100%;
  height: 1px;
  position: absolute;
  top: -2px;
  left: 0;
  border-top: 2px solid #c77a16;
}
.operationBar .secondLevel .functions.active {
  color: #c77a16;
  background: linear-gradient(#18172a, rgba(24, 23, 42, 0.5));
}
.sensor {
  display: inline-flex;
  width: 48%;
  margin: 12px 1%;
  height: 60px;
  font-size: 12px;
  border-radius: 6px;
  background-color: rgba(12, 34, 46, 0.8);
  justify-content: space-around;
  align-items: center;
  padding: 6px;
  vertical-align: bottom;
}
.sensor .sensorStatus {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.sensorDot {
  width: 20px;
  height: 20px;
  border-radius: 10px;
}
.sensor .status-1 {
  background-color: #e11523;
  filter: drop-shadow(0 0 6px #e11523);
}
.sensor .status-2 {
  background-color: #de8b5b;
  filter: drop-shadow(0 0 6px #de8b5b);
}
.sensor .status-3 {
  background-color: #dec963;
  filter: drop-shadow(0 0 6px #dec963);
}
.sensor .status-4 {
  background-color: #969899;
  filter: drop-shadow(0 0 6px #969899);
}
.sensor .status-5 {
  background-color: #00fa9a;
  filter: drop-shadow(0 0 6px #00fa9a);
}
.sensor .inside {
  background: radial-gradient(circle, #e11523, #00fa9a);
  width: 80px;
  height: 3px;
}
.sensor .inside {
  background: radial-gradient(circle, #00fa9a, #e11523);
  width: 80px;
  height: 3px;
}
.sensor .linear {
  background: linear-gradient(90deg, #00fa9a, #e11523);
  width: 80px;
  height: 3px;
}
.sensor .revLinear {
  background: linear-gradient(90deg, #e11523, #00fa9a);
  width: 80px;
  height: 3px;
}
.sharp {
  position: absolute;
  margin-left: -9px;
}
.sensorName {
  overflow: hidden;
  max-height: 100%;
}
.sensorFiles {
  max-height: 340px;
  overflow: auto;
}
.sensorFiles .sensorFile {
  padding: 4px;
  background-color: rgba(12, 34, 46, 0.8);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #fff;
  display: block;
  border-bottom: 1px solid hsla(201, 59%, 30%, 0.8);
}
.deviceBaseInfo {
  font-size: 14px;
  line-height: 32px;
  background-color: #f8f8f9;
  padding: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.deviceBaseInfo i {
  width: 30px;
  height: 30px;
  display: inline-block;
  text-align: center;
  margin-right: 6px;
}
.deviceBaseInfo:not(:last-child):after {
  content: "";
  display: block;
  left: 40px;
  position: absolute;
  right: 0;
  height: 1px;
  border-bottom: 1px solid #536370;
}
.deviceBaseInfo > span:last-child {
  font-size: 12px;
  text-align: right;
}
.deviceConnect {
  display: flex;
  flex-direction: row;
  background-color: #f8f8f9;
  align-items: center;
  padding: 0 4px;
}
.deviceConnect .deviceStream {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 30px;
  height: fit-content;
}
.deviceConnect .deviceStream:first-child {
  flex-grow: 1;
}
.deviceConnect .deviceStream:first-child:after {
  content: "";
  display: block;
  position: absolute;
  top: 16px;
  bottom: 16px;
  width: 1px;
  border-left: 1px solid #fff;
  right: 0;
}
.deviceConnect .deviceStream:first-child span {
  display: block;
  width: 70%;
  text-align: right;
}
.deviceConnect .deviceStream:first-child span:after {
  content: "";
  display: block;
  position: absolute;
  top: 16px;
  width: 24px;
  height: 1px;
  border-bottom: 1px solid #fff;
  right: -27px;
}
.deviceConnect .deviceStream:last-child {
  flex-grow: 1;
}
.deviceConnect .deviceStream:last-child:after {
  content: "";
  display: block;
  position: absolute;
  top: 16px;
  bottom: 16px;
  width: 1px;
  border-left: 1px solid #fff;
  left: 0;
}
.deviceConnect .deviceStream:last-child span {
  display: block;
  width: 70%;
}
.deviceConnect .deviceStream:last-child span:after {
  content: "";
  display: block;
  position: absolute;
  top: 16px;
  width: 24px;
  height: 1px;
  border-bottom: 1px solid #fff;
  left: -27px;
}
.repairList {
  border: 1px solid rgba(255, 255, 255, 0.6);
  margin: 12px 6px;
}
.repairList .repairItem {
  padding: 4px;
}
.repairList .repairItem .icon {
  width: 40px;
  display: inline-block;
  text-align: center;
  line-height: 40px;
  vertical-align: text-bottom;
  font-size: 22px;
}
.repairList .repairItem .detail {
  display: inline-block;
}
.repairList .repairItem .detail .number {
  color: dodgerblue;
}
.repairList .repairItem .detail .describe {
  font-size: 16px;
}
.repairList .repairItem .detail .date {
  color: #ddd;
}
.repairList .repairItem:not(:last-child):after {
  content: "";
  display: block;
  width: 390px;
  position: absolute;
  border-bottom: 1px solid #888;
  bottom: 0;
  right: 0;
}
.repairList .repairItem .status {
  float: right;
  text-align: center;
  border-radius: 50px;
  color: white;
  line-height: 26px;
  width: 80px;
  margin-top: 8px;
}
.repairList .repairItem .status.status-1 {
  background-color: rgba(247, 53, 53, 0.7);
}
.repairList .repairItem .status.status-1:after {
  content: "新建";
}
.repairList .repairItem .status.status-2 {
  background-color: rgba(50, 162, 220, 0.7);
}
.repairList .repairItem .status.status-2:after {
  content: "处理中";
}
.repairList .repairItem .status.status-3 {
  background-color: rgba(69, 185, 104, 0.7);
}
.repairList .repairItem .status.status-3:after {
  content: "已解决";
}
.repairList .repairItem .status.status-4 {
  background-color: rgba(170, 170, 170, 0.7);
}
.repairList .repairItem .status.status-4:after {
  content: "已完成";
}
.footerList {
  position: absolute;
  bottom: 20px;
  left: 190px;
}
.leftList {
  position: absolute;
  top: 40%;
  left: 20px;
  display: flex;
  z-index: 10;
  text-align: center;
  cursor: pointer;
  flex-direction: column;
  pointer-events: all;
  background: rgb(244 248 251);
  color: #788CA1;
  padding: 10px 6px;
  border-radius: 4px;
}
.leftList > div {
  font-size: 12px;
  padding: 5px;
  margin: 3px 0;
}
.leftList .leftList_active {
  background: #4261ED;
  border-radius: 10px;
  color: #ffffff;
}
.restView {
  position: static;
  float: left;
}
.roomMessage {
  display: flex;
}
.list-icon {
  display: flex;
  width: 40px;
  align-items: center;
}
.list-icon img {
  height: 17px;
  display: block;
  zoom: normal;
  margin: 0 auto;
}
.roomcontent {
  display: flex;
  flex-grow: 1;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  padding: 10px;
  justify-content: space-between;
}
.tabsContent {
  height: 300px;
  margin: 0 15px 15px 15px;
  border-radius: 0 0 5px 5px;
  border: 1px solid rgba(50, 162, 220, 0.5);
  border-top: none;
  padding-bottom: 10px;
  overflow: auto;
}
.Btns {
  background: green;
  text-align: center;
  border-radius: 10px;
  width: 60px;
  padding: 2px 10px;
  font-size: 12px;
}
.searchModel {
  padding: 10px;
  box-sizing: border-box;
  width: 170px;
  position: absolute;
  bottom: 70px;
  font-family: "微软雅黑";
  background: rgba(16, 30, 43, 0.8);
  border: 1px solid #101e2b;
  border-radius: 5px;
}
.searchModel .searchBody {
  height: 100px;
  overflow: auto;
  font-size: 14px;
}
.searchModel .searchBody > div > div {
  overflow: hidden;
  width: 100%;
  padding: 0 3px;
  text-align: left;
  text-overflow: ellipsis;
  font-family: "微软雅黑" !important;
  white-space: nowrap;
  line-height: 25px;
  font-size: 13px;
}
.myinput {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  line-height: 40px;
  font-size: 14px;
  background: rgba(50, 162, 220, 0.2);
  outline: none;
  padding-left: 25px;
  border: none;
}
.myinput::-webkit-input-placeholder {
  color: white;
}
.List-active {
  background: rgba(50, 162, 220, 0.2);
}
.zhe {
  background: url("./assets/zhe2.png") center;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}
 /deep/ .roomTabs .ivu-tabs-bar {
  margin-bottom: 0;
}
 /deep/ .roomTabs .ivu-tabs-ink-bar {
  visibility: hidden !important;
}
 /deep/ .roomTabs .ivu-tabs-nav {
  width: 100%;
}
 /deep/ .roomTabs .ivu-tabs-nav .ivu-tabs-tab {
  padding: 9px 0;
  margin: 0;
  width: 20%;
}
 /deep/ .roomTabs .ivu-tabs-nav .ivu-tabs-tab div span {
  margin: 0 auto !important;
}
 /deep/ .roomTabs .ivu-tabs-nav .ivu-tabs-tab div img {
  margin: 0 auto !important;
}
 /deep/ .ivu-table-cell {
  color: #333;
}
 /deep/ th .ivu-table-cell {
  color: #999;
}
 /deep/ .ivu-table:before {
  background-color: #fff0;
}
 /deep/ .ivu-modal img,
 /deep/ .ivu-modal video {
  width: 100%;
}
 /deep/ .vjs-button > .vjs-icon-placeholder {
  position: relative;
  top: -15px;
}
 /deep/ .ivu-table-cell {
  padding-left: 10px;
  padding-right: 10px;
}
