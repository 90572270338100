<style lang="less" scoped>
    .bimface_yunwei{
        width: 100%;
        height: 100%;
        position:fixed;
        #bimfaceId{
            width: 100%;
            height: calc(100% - 60px);
            margin: 60px 0 0;
            transition: width 1s ease;background: #d5dfeb;
        }
    }
</style>
<template>
    <div class="bimface_yunwei">
        <div id="bimfaceId" v-if="showDom" :style="{'width':isappDrawBack?'100%':'calc(100% - 750px)'}"></div>
    </div>
</template>
<script lang="ts">
	import request from "@/request";
	import { Component, Vue, Watch, Prop } from "vue-property-decorator";
	// @ts-ignore
	import Bus from "./Bus";
	@Component({})
		export default class bimfaceMain extends Vue {
        @Prop() readonly routerType:any;
		viewToken:string= "";
		viewer3D:any= null;
        model3D:any = null;
		drawable:any= null;
        floorId:any = "";
        modelData:any = [];
        isappDrawBack: boolean = false;
        modelState:any = "{\"state\":{\"2698353489621024\":{\"isolateAction\":null,\"actions\":[],\"sceneState\":0,\"version\":\"1.0\"},\"ExtrudeBodyManager\":{\"isolateAction\":null,\"actions\":[],\"sceneState\":0,\"version\":\"1.0\"}},\"camera\":{\"name\":\"persp\",\"position\":{\"x\":36560.02022233887,\"y\":57190.52263929981,\"z\":234280.45628371302},\"target\":{\"x\":36560.02022233887,\"y\":57190.53441735658,\"z\":-35170.06220870297},\"up\":{\"x\":0,\"y\":0.9999999999999991,\"z\":4.3711411827906026e-8},\"near\":195032.78154385166,\"far\":243678.12846324686,\"zoom\":1.234134359056,\"version\":1,\"fov\":45,\"aspect\":1.4556500607533414,\"coordinateSystem\":\"world\"},\"selection\":[],\"axisGrid\":{\"fileInfos\":[],\"gridLineColor\":{\"red\":51,\"green\":51,\"blue\":51,\"alpha\":1},\"gridBubbleColor\":{\"red\":0,\"green\":0,\"blue\":0,\"alpha\":1},\"bIsBringToFront\":false,\"bIsEnableHover\":true}}";
        showDom = true;
        @Watch("routerType")
        onRouterTypeChange (newVal, oldVal) {
            // 默认false
            this.isappDrawBack = (newVal === "energyEle" || newVal === "elevatormain2");
            this.$store.commit("changeDraw", (newVal === "energyEle" || newVal === "elevatormain2"));
            // console.log(newVal, "9999999999999999999", this.isappDrawBack);
            // 模型初始化
            if (this.viewer3D) {
                // 切换模型 设备管理为机电模型
                if (newVal === "elevatormain2" || oldVal === "elevatormain2" || newVal === "repairTable" || oldVal === "repairTable") {
                    this.showDom = false;
                    this.viewer3D.destroy();
                    this.viewer3D = null;
                    setTimeout(() => {
                        this.showDom = true;
                        this.$nextTick(() => {
                            this.getModelData();
                        });
                    }, 0);
                } else {
                    // 切换楼层
                    if (this.drawable) {
                        this.drawable.clear();
                    }
                    this.setBimFloor(newVal);
                }
            } else if (this.routerType != "home2") {
                this.$nextTick(() => {
                    this.getModelData();
                });
            }
        }
        @Watch("$store.state.isDrawBack")
        onisDrawBackchaneg (val) {
            this.isappDrawBack = val;
            setTimeout(() => {
                if (this.viewer3D) {
                    this.viewer3D.resize(document.getElementById("bimfaceId").clientWidth, document.getElementById("bimfaceId").clientHeight);
                }
            }, 1000);
        }
        setBimFloor (newVal) {
             /** 设备 根据空间楼层id 过滤的
             *  监控---1F 1000178
                用水---1F
                用电---1F
                气体--- (-1F) 1000177
                机电设备--2F 1000179
            */
            if (newVal == "gas") {
                this.floorId = "1000177"; // 气体11536 11537   11539水表  11540电表
            } else if (newVal == "elevatormain2") {
                this.floorId = "1000179"; // 机电11541
            } else {
                this.floorId = "1000178";
            }
            // 兄弟组件楼层active
            Bus.emit("changeKongjianFloorId", this.floorId);
            this.getGoujian();
        }
        getGoujian () {
            if (this.drawable) {
                this.drawable.clear();
            }
            if (this.model3D) {
                this.model3D.hideAllComponents();
            }
            return new Promise((resolve) => {
                // 整体模型， 建筑模型
                request(`/model/rest/pb/?format=json&&revitfilename=${ this.modelData[(this.routerType === "elevatormain2" || this.routerType === "repairTable") ? 5 : 4].subid }&floor=${ this.floorId }&pagesize=99999`).then(({ data }) => {
                    const newData = data.map((val) => (this.routerType === "elevatormain2" || this.routerType === "repairTable") ? `${ val.revitfilename }.${ val.elementid }` : val.elementid);
                    // console.log(newData[0]);
                    this.model3D.showComponentsById(newData);
                    if (this.viewer3D) {
                        this.viewer3D.resize(document.getElementById("bimfaceId").clientWidth, document.getElementById("bimfaceId").clientHeight);
                    }
                    // 能耗-用电切换到气体时候，房间设备数据会先展示，所以才作了延时
                    let timeout = 500;
                    setTimeout(() => {
                        if (this.routerType === "mvideo") {
                            this.getcamera();
                        } else if ((this.routerType === "elevatormain2")) {
                            // 设备类型id
                            this.getdevice(11541);
                        } else if (this.routerType === "energyWater") {
                            this.getdevice(11539);
                        } else if (this.routerType === "energyEle") {
                            this.getdevice(11540);
                        } else if (this.routerType === "gas") {
                            this.getdevice(11536);
                            this.getdevice(11537);
                        }
                        this.getroom();
                    }, timeout);
                    // @ts-ignore
                    resolve();
                });
            });
        }
		renderModel (viewToken) {
			this.viewToken = viewToken;
			let options = new BimfaceSDKLoaderConfig();
			options.viewToken = viewToken;
			BimfaceSDKLoader.load(options, this.successCallback, this.failureCallback);
		}
		successCallback (viewMetaData) {
			if (viewMetaData.viewType == "3DView") {
				// ======== 判断是否为3D模型 ========
				// 获取DOM元素
				let dom4Show = document.getElementById("bimfaceId");
				let webAppConfig = new Glodon.Bimface.Application.WebApplication3DConfig();
				webAppConfig.Buttons = [];
				webAppConfig.Toolbars = [];
				webAppConfig.domElement = dom4Show;
                webAppConfig.globalUnit = Glodon.Bimface.Common.Units.LengthUnits.Millimeter;
				// 创建WebApplication
				let app = new Glodon.Bimface.Application.WebApplication3D(webAppConfig);
				// 添加待显示的模型
				app.addView(this.viewToken);
				// 从WebApplication获取viewer3D对象
				this.viewer3D = app.getViewer();
                // this.viewer3D.loadModel({
                //     viewToken: this.viewToken,
                //     modelId: 1
                // });

				// 监听添加view完成的事件
				let that = this;
				this.viewer3D.addEventListener(Glodon.Bimface.Viewer.Viewer3DEvent.ViewAdded, function () {
					// 自适应屏幕大小
					window.onresize = function () {
						that.viewer3D.resize(document.getElementById("bimfaceId").clientWidth, document.getElementById("bimfaceId").clientHeight - 40);
					};
                    that.viewer3D.setState(that.modelState);
                    // that.viewer3D.setView("Top");
                    that.viewer3D.enableOrbit(false); // 不可旋转
                    that.viewer3D.render();
                    // window.viewer3D = that.viewer3D;
                    that.model3D = that.viewer3D.getModel();
					// 默认展示 设备点位
                    that.setBimFloor(that.routerType);
				});
				let drawableConfig = new Glodon.Bimface.Plugins.Drawable.DrawableContainerConfig();
				drawableConfig.viewer = this.viewer3D;
				drawableConfig.maxNum = 10;
				this.drawable = new Glodon.Bimface.Plugins.Drawable.DrawableContainer(drawableConfig);
				// this.viewer3D.addEventListener(Glodon.Bimface.model3D.Viewer3DEvent.MouseClicked, this.getModelLabel);
			}
		}
		failureCallback () {}
        getModelData () {
            request("/model/rest/bimface/?format=json").then(({ data }) => {
                if (data.results.length >= 6) {
                    this.modelData = data.results;
			        this.renderModel(data.results[(this.routerType === "elevatormain2" || this.routerType === "repairTable") ? 5 : 4].viewtoken);
                }
            });
        }
        getdevice (devicetype) {
            request(`/device/device/?format=json&format=json&devicetype=${ devicetype }&pagesize=999&room__floor=${ this.floorId }`).then(({ data }) => {
                data.results.forEach((val) => {
                    if (val.pbposition) {
                        this.setPoint(val, 3);
                    }
                });
            });
        }
        getcamera (type = "") {
            request(`/smt/rest/camera/?format=json&pagesize=999&room__floor=${ this.floorId }`).then(({ data }) => {
                data.results.forEach((val) => {
                    if (val.pbposition) {
                        this.setPoint(val, 2);
                    }
                });
            });
        }
        getroom () {
            request(`/space/room/namecolor/?format=json&floor=${ this.floorId }&pagesize=999`).then(({ data }) => {
                data.forEach((val) => {
                    if (val.pbposition) {
                        this.setPoint(val, 1);
                    }
                });
            });
        }
        setPoint (value, type) {
            if (this.drawable) {
                // 引线标签的顶点
                const position = JSON.parse(value.pbposition);
                const config = new Glodon.Bimface.Plugins.Drawable.CustomItemConfig();
                const rectangle = document.createElement("div");
                // 自定义样式，支持Html的任意dom元素 type  房间  摄像头 设备
                if (type == 1) {
                    rectangle.className = "sys_ponit_1";
                    rectangle.innerHTML = `<span>${ value.name }</span>`;
                    // 维修页面-房间显示状态
                    if (this.routerType === "repairTable") {
                        if (value.status == 1) {
                            rectangle.className = "sys_ponit_1 sys_ponit_add";
                        } else if (value.status == 2) {
                            rectangle.className = "sys_ponit_1 sys_ponit_deal";
                        } else {
                            rectangle.className = "sys_ponit_1";
                        }
                    }
                } else if (type == 2) {
                    rectangle.className = "sys_ponit sys_ponit_2";
                    // 用作视频icon高亮
                    rectangle.id = `sys_ponit_video_${value.id}`;
                    rectangle.innerHTML = "<p class='sys_ponit_bg sys_ponit_onVideo'></p>";
                    config.tooltip = value.name;
                    config.offsetY = -32;
                } else if (type == 3) {
                    rectangle.className = "sys_ponit sys_ponit_3";
                    let myclass = "sys_ponit_co";
                    if (value.devicetypen.indexOf("电") > -1) {
                        myclass = "sys_ponit_dian";
                    } else if (value.devicetypen.indexOf("co2") > -1) {
                        myclass = "sys_ponit_co2";
                    } else if (value.devicetypen.indexOf("水") > -1) {
                        myclass = "sys_ponit_water";
                    } else if (value.devicetypen.indexOf("室内机") > -1) {
                        myclass = "sys_ponit_onShineiji";
                    }
                    rectangle.innerHTML = `<p class='sys_ponit_bg ${ myclass }'></p>`;
                    config.tooltip = value.serialnumber;
                    config.offsetY = -32;
                } else {
                    rectangle.className = "sys_ponit";
                    config.offsetY = -32;
                }
                config.deviceId = value.id;
                config.typeId = type;
                config.content = rectangle;
                config.viewer = this.viewer3D;
                config.worldPosition = position;
                // 生成customItem实例
                const customItem = new Glodon.Bimface.Plugins.Drawable.CustomItem(config);
                let that = this;
                // 左键点击
                customItem.onClick(function (item) {
                    if (that.routerType === "repairTable") {
                        if (that.$store.state.relatedid === item._config.deviceId) {
                            that.$store.commit("searchTableRoom", {
                                type: "",
                                id: ""
                            });
                            rectangle.style.transform = "scale(1)";
                        } else {
                            that.$store.commit("searchTableRoom", {
                                type: item._config.typeId === 1 ? "房间" : "设备",
                                id: item._config.deviceId
                            });
                            let doms:any = document.getElementsByClassName("sys_ponit_1");
                            doms?.forEach(d => {
                                d.style.transform = "scale(1)";
                            });
                            rectangle.style.transform = "scale(1.3)";
                        }
                    }else if(that.routerType ===  "mvideo"){
                        // 清除
                        let tempArr:any = document.getElementsByClassName("sys_ponit_2");
                        if(tempArr.length > 0){
                            tempArr?.forEach(item => {
                                item.style.outline = "none";
                            })
                        }
                        // 选中
                        document.getElementById(`sys_ponit_video_${value.id}`).style.outline = "3px solid #4261ed";
                    }
                    // that.$store.commit("clickShebei", item._config.deviceId);
                    that.$store.commit("clickShebei", value);
                    console.log("点击设备deviceId:", item._config.deviceId, that.$store.state.shebeiModal, that.routerType, value.id);
                });
                // 右键点击
                rectangle.addEventListener("contextmenu", function (event) {
                    event.preventDefault();
                    if (type === 1) {
                        that.$store.commit("triggerAdd", {
                            floorid: value.floor_id,
                            roomid: value.id
                        });
                    }
                });
                // 添加自定义标签
                this.drawable.addItem(customItem);
                 // 增加一个Tip提示
                // 设置Tip的样式
                customItem.setTooltipStyle({
                    border: "1px",
                    top: "-33px",
                    left: "-17px"
                });
            }
        }
        mounted () {
            Bus.on("changeFloorId", (id) => {
                if (this.floorId != id) {
                    this.floorId = id;
                    if (this.viewer3D) {
                        this.getGoujian();
                    }
                }
            });
		}
	}
</script>
<style>
.sys_ponit{
    width: 26px;
    height: 26px;
    line-height: 26px;
    text-align: center;
    border: 1px solid rgb(255, 255, 255);
    border-radius: 13px 13px 0px;
    transform: rotate(45deg);
    font-size: 20px;
    position: relative;
    left: -13px;
    cursor: pointer;
}
.sys_ponit_1{
    width: 160px;
    font-size: 16px;
    line-height: 16px;
    position: relative;
    left: -80px;
    text-align: center;
    color: #333;
    font-weight: 600;
    text-shadow: #fff 1px 0 0, #fff 0 1px 0, #fff -1px 0 0, #fff 0 -1px 0;
    pointer-events: none;
}
.sys_ponit_deal{
    color: #EF9F24;
    animation: neon 3s ease-in-out infinite;
}
.sys_ponit_add{
    color: #FD4848;
    animation: neon 3s ease-in-out infinite;
}
@keyframes neon {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.2;
    }
    100% {
        opacity: 1;
    }
}
.sys_ponit_1>span{
    pointer-events: all;
    cursor:pointer;
}
.sys_ponit_2{
    background: #DEFCDE;
}
.sys_ponit_bg{
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    transform: rotate(-45deg);
}
.sys_ponit_3{
    background: #DEFCDE;
}
.sys_ponit_onVideo{
    background-image: url("../assets/bimface/onVideo.svg");
}
.sys_ponit_co{
    background-image: url("../assets/bimface/co.svg");
}
.sys_ponit_co2{
    background-image: url("../assets/bimface/co2.svg");
}
.sys_ponit_dian{
    background-image: url("../assets/bimface/dian.svg");
}
.sys_ponit_onShineiji{
    background-image: url("../assets/bimface/onShineiji.svg");
}
.sys_ponit_water{
    background-image: url("../assets/bimface/water.svg");
}
</style>
