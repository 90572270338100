.mymessage_wrap {
  position: absolute;
  top: 60px;
  right: 10px;
  z-index: 108;
  pointer-events: all;
  color: #788CA1;
  box-shadow: 0px 0px 5px 1px #80b7fb;
  background: #fff;
  border-radius: 4px;
  width: 400px;
}
.mymessage_wrap .mymessage_title {
  display: flex;
  font-size: 18px;
  justify-content: space-evenly;
  padding: 9px 0;
}
.mymessage_wrap .mymessage_title > div {
  display: flex;
  text-align: center;
  flex-wrap: wrap;
  cursor: pointer;
}
.mymessage_wrap .mymessage_title > div > span,
.mymessage_wrap .mymessage_title > div > p {
  width: 100%;
}
.mymessage_wrap .mymessage_title > div > span > span,
.mymessage_wrap .mymessage_title > div > p > span {
  color: #788CA1;
  font-size: 12px;
  margin-left: 3px;
}
.mymessage_wrap .mymessage_title > div .mymessage_title_p0 {
  color: #FD4848;
}
.mymessage_wrap .mymessage_title > div .mymessage_title_p1 {
  color: #EF9F24;
}
.mymessage_wrap .mymessage_title > div .mymessage_title_p2 {
  color: #4261ED;
}
.mymessage_wrap .mymessage_title .mymessage_title_active {
  font-weight: 600;
}
.mymessage_wrap .gary_line {
  width: calc(100% - 20px);
  height: 2px;
  position: relative;
  left: 10px;
  bottom: 0;
  background-color: rgb(247 247 250);
}
.mymessage_wrap .gary_line::after {
  content: "";
  position: absolute;
  width: 8px;
  height: 2px;
  top: 0px;
  background: #e0e2e3;
  right: 0;
}
.mymessage_wrap .gary_line::before {
  content: "";
  position: absolute;
  width: 8px;
  height: 2px;
  top: 0;
  background: #e0e2e3;
  left: 0;
}
.mymessage_wrap #mymessage_Dom {
  height: 50vh;
  overflow: auto;
}
.mymessage_wrap:before {
  content: "";
  position: absolute;
  right: 65px;
  top: -29px;
  border-top: 15px solid transparent;
  border-bottom: 15px solid rgb(244 248 251);
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  z-index: 1;
}
.mymessage_wrap ul {
  padding: 0 6px;
  width: 390px;
}
.mymessage_wrap ul li {
  font-size: 14px;
  padding: 5px;
  margin: 3px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.mymessage_wrap ul li img {
  width: 20px;
  margin: 0 8px 0 0 ;
  transform: rotate(45deg);
}
.mymessage_wrap ul li .message_p1 {
  margin: 0 8px 0 0 ;
}
.mymessage_wrap ul li .message_p1_3 {
  color: #ef7878;
}
.mymessage_wrap ul li .message_p1_1 {
  color: #eac181;
}
.mymessage_wrap ul li .message_p1_2 {
  color: #8495e0;
}
.mymessage_wrap ul li .message_p3 {
  width: 70px;
  color: #999;
  font-size: 12px;
}
.mymessage_wrap ul li .message_p2 {
  color: #788CA1;
  width: 234px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.mymessage_wrap ul .floor_active {
  background: #4261ED;
  border-radius: 10px;
  color: #ffffff;
}
.mymessage_wrap .mymessage_time {
  padding: 5px 5px;
  display: flex;
  justify-content: center;
}
